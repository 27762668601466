import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { findAssetCode } from './Asset';



function App() {
   const [mtFile, setMtFile] = useState(null);
  const [mudlFile, setMudlFile] = useState(null);
  const [mrValue, setMrValue] = useState('');
  const [tmrPassValue, settmrPassValue] = useState('');
  const tmrPass = toString;

  const readExcelFile = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      callback(data);
    };
    reader.readAsBinaryString(file);
  };

  const handleMTImport = (e) => {
    const file = e.target.files[0];
    setMtFile(file);
    setMrValue(''); // Resetting mrValue
    settmrPassValue(''); // Resetting tmrPassValue
    
    readExcelFile(file, (mtData) => {
      // Assuming EJ2 contains the MR value
      const mrNo = mtData[1][139]; // EJ2 is the 145th column, 2nd row
      const tmrPass = mtData[1][1]; // A2 Passphrase
      setMrValue(mrNo);
      settmrPassValue(tmrPass);
      
      const updatedTmrPass = incrementTmrPassSequence(tmrPass);
      settmrPassValue(updatedTmrPass);
    });
  };

  const handleMudlImport = (e) => {
    const file = e.target.files[0];
    setMudlFile(file);
  };

  const incrementTmrPassSequence = (tmrPass) => {
    const chkMatch = tmrPass.match(/CHK (\d+)/);
    if (chkMatch && chkMatch[1]) {
      const sequenceNumber = parseInt(chkMatch[1], 10) + 1;
      return tmrPass.replace(/CHK \d+/, `CHK ${sequenceNumber}`);
    }else {
      return tmrPass + " CHK 1"; 
    }
    // return tmrPass;
  };

  const excelSerialDateToJSDate = (serial) => {
    const utc_days  = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;                                        
    return new Date(utc_value * 1000);
  };
  
  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
  
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
  
    return [month, day, year].join('/');
  };
  
  const formatTime = (serialTime) => {
    const totalSeconds = Math.floor(86400 * serialTime); // Total seconds in a day * fraction
    const hours = Math.floor(totalSeconds / 3600); // Total seconds divided by seconds in an hour
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Remaining seconds divided by seconds in a minute
  
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    return `${hours}:${formattedMinutes}`;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const processData = () => {
    if (!mtFile || !mudlFile) {
      alert('Please import both files first!');
      return;
    }

    readExcelFile(mtFile, (mtData) => {
      const mrNo = mtData[1][139]; // EJ2

      if (tmrPassValue !== "") {
        const tmrPassValue = mtData[1][1]; // A2 Passphrase
      }

      // setMrValue(mrNo);
      // settmrPassValue(tmrPass);
        
      const newData = [
        ["Plan Name", "HAZ", "Type", "TMR No", "MR#", "Status", "Priority", "Sys Create Date", "Requestor Organization",
          "Requestor Phone No", "Requestor POC", "Requestor UIC", "Movement Credit No", "Exercise Name", "Fund Cite",
          "TMR Transponder", "TMR RFID", "Destination Stops", "Mode\nMethod", "MM", "Commercial Carrier", "Asset Type",
          "Quantity", "Origin\nDODAAC", "Origin Stop Poc Information", "Origin POC Phone", "Origin POC Other", "Origin Building",
          "Origin Street Address", "Origin\nCity", "Origin State", "Origin Country", "Origin Stop Installation", "Spot\nDate",
          "Spot Time", "LoadDate", "Load Time", "Pull\nDate", "Pull Time", "Dest\nDODAAC", "Dest\nMCE", "Destination POC Information",
          "Destination POC Phone", "Destination POC Other", "Destination Building", "Destination Street Address", "Dest\nCity",
          "Destination State", "Destination Country", "Destination Installation", "RDD", "RDD Time", "High Visibility Move",
          "Total Weight", "First Cargo Supply Class", "First Cargo Description", "ITV Link", "Action required by",
          "MCO that MR submitted to for CE", "If MHE origin requested", "If MHE destination requested", "Initiation Date",
          "Current Date", "Estimated Cost", "Cost Status", "Actual Cost", "FM", "Operation/Exercise", "FC Owner",
          "Fiscal Year", "Created By", "TAC", "Fund", "Fund Center", "Approved by", "Requesting Unit", "Next Action",
          "Cargo TCN", "Cargo Description", "Cargo Container ID", "Cargo Type", "Pieces", "Cargo Weight", "Cube", "Length",
          "Width", "Height", "Cargo Supply Class", "Comm", " Cargo Transponder", "Cargo RFID", "Controlled Move", "Pax Count"],


        [
          tmrPassValue,   // Passphrase located in 2
          mtData[1][117], // HAZ located in 118
          mtData[1][131], // Type located in 132
          mtData[1][10],  // TMR no located in 11
          mtData[1][139],  // MR no located in 140
          mtData[1][178],  // Status located in 179
          "3",  // Priority
          "", // SysCreate
          mtData[1][141], // Requestor Orgnatization
          mtData[1][142], // Requestor Phone
          mtData[1][143], // Requestor POC
          mtData[1][144], // Requestor UIC
          mtData[1][145], // Movement Credit
          mtData[1][146], // Exercise Name
          mtData[1][147], // Fund Cite
          mtData[1][118], // TMR Transponder
          mtData[1][120], // TMR RFID
          mtData[1][148], // Dest Stops
          mtData[1][134], // Mode Method
          mtData[1][121], // MM
          mtData[1][149], // Commercial Carrier
          "", // Asset Type
          mtData[1][122], // Quantity
          mtData[1][160], // OrgDoddaac
          mtData[1][22], // Org Stop POC Information
          "", // Origin POC Phone(it is combined above)
          "", // Origin POC Phone Other (Always blank)
          mtData[1][151], // Org Building
          mtData[1][152], // Org Address
          mtData[1][25], // Org City
          "", // Org State
          mtData[1][153], // Org Country
          mtData[1][25], // Org Installation
          "", // Spot
          "", // Spot Time
          mtData[1][6], // Load
          mtData[1][7], // Load Time
          mtData[1][137], // Pull
          mtData[1][138], // Pull time
          mtData[1][26], // DestinationDodaac
          mtData[1][133], // Destination MCE
          mtData[1][27], // Destination POC Information
          "", // Destination POC Phone (already combined above)
          "", // Destination POC Other
          mtData[1][155], // Destination Bulding
          mtData[1][156], // Destination Address
          mtData[1][30], // Destination City
          "", // Destination State
          mtData[1][157], // Destination Country
          mtData[1][30], // Destination Installation
          mtData[1][8], // RDD
          mtData[1][9], // RDD Time
          "", // High Vis
          mtData[1][124], // Total Weight
          "", // First Cargo Supply class
          mtData[1][55], // First Cargo Description
          mtData[1][158], // ITV Link / remarks
          mtData[1][159], // Action Required by
          mtData[1][160], // MCO that MR submitted to for CE
          mtData[1][161], // If MHE origin requested
          mtData[1][162], // If MHE destination requested
          mtData[1][163], // Initiation Date
          mtData[1][164], // Current Date
          "", // Estimated Cost
          mtData[1][179], // Cost Status
          "", // Actual Cost
          mtData[1][167], // FM
          mtData[1][168], // Operation/Exercise
          mtData[1][169], // FC Owner
          mtData[1][170], // Fiscal Year
          mtData[1][171], // Created by
          mtData[1][172], // TAC
          mtData[1][173], // Fund
          mtData[1][174], // Fund center
          mtData[1][175], // Approved by
          mtData[1][176], // Requesting unit
          mtData[1][177], // Next Action
          mtData[1][16], // TCN
          mtData[1][15], // Cargo Description
          mtData[1][18], // Container ID
          mtData[1][119], // Cargo Type
          mtData[1][122], // Pieces
          mtData[1][17], // Cargo Weight
          mtData[1][127], // Cube
          mtData[1][128], // Length
          mtData[1][129], // Width
          mtData[1][130], // Height
          "", // Cargo Suppy Class
          mtData[1][140], // Comm
          "", // Cargo Transponder
          "", // Cargo RFID
          mtData[1][125], // Controlled Move
          mtData[1][126], // Pax Count
        ]

      ];
      let classificationValue;
      const classification = mtData[1][13];

      if (classification === 'CL I') {
        classificationValue = '1X';
      } else if (classification === 'CL II') {
        classificationValue = '2X';
      } else if (classification === 'CL III') {
        classificationValue = '3X';
      } else if (classification === 'CL IV') {
        classificationValue = '4X';
      } else if (classification === 'CL V') {
        classificationValue = '5X';
      } else if (classification === 'CL VI') {
        classificationValue = '6X';
      } else if (classification === 'CL VII') {
        classificationValue = '7X';
      } else if (classification === 'CL VIII') {
        classificationValue = '8X';
      } else if (classification === 'CL IX') {
        classificationValue = '9X';
      } else {
        classificationValue = classification; // default case if none of the conditions are met
      }

      
      newData[1][7] = formatDate(excelSerialDateToJSDate(mtData[1][136])); // SysCreate date
      newData[1][21] = findAssetCode((mtData[1][35])); // Asset Type

      newData[1][33] = formatDate(excelSerialDateToJSDate(mtData[1][4])); // SPOT DATE
      newData[1][34] = formatTime((mtData[1][5])); // SPOT TIME
      newData[1][35] = formatDate(excelSerialDateToJSDate(mtData[1][6])); // Load DATE
      newData[1][36] = formatTime((mtData[1][7])); // Load TIME
      newData[1][37] = formatDate(excelSerialDateToJSDate(mtData[1][137])); // Pull DATE
      newData[1][38] = formatTime((mtData[1][138])); // Pull TIME
      newData[1][50] = formatDate(excelSerialDateToJSDate(mtData[1][8])); // RDD DATE
      newData[1][51] = formatTime((mtData[1][9])); // RDD TIME
           
      newData[1][54] = classificationValue;
      newData[1][87] = classificationValue;

      newData[1][61] = formatDate(excelSerialDateToJSDate(mtData[1][163])); // Initiation date
      newData[1][62] = formatDate(excelSerialDateToJSDate(mtData[1][164])); // Current Date
      newData[1][64] = formatCurrency((mtData[1][165])) // Estimated Cost
      newData[1][65] = formatCurrency((mtData[1][166])) // Acutal Cost

      readExcelFile(mudlFile, (mudlData) => {
        if (mudlData.length > 2) {
          // // Consolidate data from MT file
          // const column4Data = mudlData.slice(1).map(row => row[3]).join(', ');
          // const column5Data = mudlData.slice(1).map(row => row[4]).join(', ');
          // const column10Data = mudlData.slice(1).map(row => row[9]).join(', ');

          const tcnColIndex = mudlData[0].findIndex(header => {
            const headerLower = header.toString().toLowerCase();
            return headerLower === 'tcn' || headerLower === 'tcn #';
          });
          const descriptionColIndex = mudlData[0].findIndex(header =>
            header.toString().toLowerCase() === 'description'
          );
          const serialColIndex = mudlData[0].findIndex(header => {
            const headerLower = header.toString().toLowerCase();
            return headerLower === 'serial' || headerLower === 'serial #';
          });

          if (tcnColIndex !== -1) {
            // Combine all data in the "description" column, excluding the first row
            const tcnData = mudlData.slice(1).map(row =>
              row[tcnColIndex] || ''
            ).join(', ');
            newData[1][77] = tcnData;
          }
          if (descriptionColIndex !== -1) {
            // Combine all data in the "description" column, excluding the first row
            const descriptionData = mudlData.slice(1).map(row =>
              row[descriptionColIndex] || ''
            ).join(', ');
            newData[1][78] = descriptionData;
          }
          if (serialColIndex !== -1) {
            // Combine all data in the "description" column, excluding the first row
            const serialData = mudlData.slice(1).map(row =>
              row[serialColIndex] || ''
            ).join(', ');
            newData[1][79] = serialData;
          }

          newData[1][81] = mudlData.length - 1;

        }

        const newWorkbook = XLSX.utils.book_new();
        const newWorksheet = XLSX.utils.aoa_to_sheet(newData);
        XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, `GDMS MT Import ${mrNo}`);

        const newFileName = `MT Import ${tmrPassValue}.xlsx`;
        XLSX.writeFile(newWorkbook, newFileName);
      });
    });
  };
  const handlePassphraseChange = (e) => {
    settmrPassValue(e.target.value);
  };
  return (
          <div className=''>
            <br />
              <div style={{paddingLeft:10}}>
                  Import the MT File:
                  <br />
                  <input type="file" accept=".xlsx, .xls" onChange={handleMTImport} />
                
                  <br />
                  <br />
                <div>
                  Import the mUDL:
                  <br />(Make sure the header row has "Description", "TCN", and Serial)
                </div>
                    <br />
                    <input type="file" accept=".xlsx, .xls" onChange={handleMudlImport} />
                    <br />
                    <br />
                    <br />
                
                  <label style={{ paddingLeft:10, paddingRight: 10 }}>MR:</label>
                  <input type="text" value={mrValue} readOnly />
                  <br />
                  <br />
                  <div className='sugg' style={{ paddingRight: 10 }}>
                    Suggested Passphrase: 
                  </div>
                  <input
                    type="text"
                    name='tmrPassphrase'
                    value={tmrPassValue}
                    onChange={handlePassphraseChange}
                    style={{ width: 400}}
                  />
                <br />
                <br />
                <button name='btnProcess' onClick={processData}>Process</button>
              </div>
          </div>
  );
}

export default App;
