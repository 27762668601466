import React from 'react'
 
const assetsArray = [
 ['I2', '.25 FLATBED TRAILER'],
 ['I5', '.5 FLATBED TRAILER'],
 ['IH', '.5 HET TRAILER'],
 ['I7', '.75 FLATBED TRAILER'],
 ['2NR', "20' Container, Non-Refrigerated"],
 ['2CC', "20' Converted HMMWV Container"],
 ['10', "20' FLATBED"],
 ['2FT', "20' Flatbed Truck, (FBT)"],
 ['H23', "20' Flatbed Truck, (FBT) with 20' Refrigerated Container"],
 ['12', "20' FLATBED W/ CONTAINER"],
 ['XB', "20' FLATBED W/ HMMWV CONTAINER"],
 ['WD', "20' FLATBED W/ RAMPS"],
 ['11', "20' FLATBED W/ SIDES"],
 ['2LB', "20' Low Boy (LB)"],
 ['13', "20' LOWBOY"],
 ['H37', "20' Lowboy Trailer (LB) with 20' Container"],
 ['XF', "20' LOWBOY W/ HMMWV CONTAINER"],
 ['2RC', "20' Refrigerated Container (Reefer)"],
 ['2NR', "20' Container, Non-Refrigerated"],
 ['2CC', "20' Converted HMMWV Container"],
 ['2FT', "20' Flatbed Truck, (FBT)"],
 ['H23', "20' Flatbed Truck, (FBT) with 20' Refrigerated Container"],
 ['2LB', "20' Low Boy (LB)"],
 ['H37', "20' Lowboy Trailer (LB) with 20' Container"],
 ['2RC', "20' Refrigerated Container (Reefer)"],
 ['2FR', "20'Flatbed Truck, (FBT) With Ramps"],
 ['2FR', "20'Flatbed Truck, (FBT) With Ramps"],
 ['3LB', "30' Low Boy (LB)"],
 ['14', "30' LOWBOY"],
 ['H38', "30' Lowboy Trailer (LB) with 20' Container"],
 ['XL', "30' LOWBOY W/ HMMWV CONTAINER"],
 ['3LB', "30' Low Boy (LB)"],
 ['H38', "30' Lowboy Trailer (LB) with 20' Container"],
 ['4NR', "40' Container, Non-Refrigerated"],
 ['15', "40' FLATBED"],
 ['4FT', "40' Flatbed Truck, (FBT)"],
 ['H24', "40' Flatbed Truck, (FBT) with 20' Container"],
 ['H28', "40' Flatbed Truck, (FBT) with 20' Freezer Container"],
 ['H34',
  "40' Flatbed Truck, (FBT) with 20' Freezer Container and 20' Refrigerated Container"],
 ['H35', "40' Flatbed Truck, (FBT) with 20' Freezer Container and 20'Regular"],
 ['H32', "40' Flatbed Truck, (FBT) with 20' HMMV Container and 20' Freezer"],
 ['H33',
  "40' Flatbed Truck, (FBT) with 20' HMMV Container and 20' Refrigerated"],
 ['H31',
  "40' Flatbed Truck, (FBT) with 20' HMMV Container and 20' Regular Container"],
 ['H26', "40' Flatbed Truck, (FBT) with 20' Refrigerated Container"],
 ['H36',
  "40' Flatbed Truck, (FBT) with 20' Refrigerated Container and 20'Regular Container"],
 ['H25', "40' Flatbed Truck, (FBT) with 2x20' Containers"],
 ['H29', "40' Flatbed Truck, (FBT) with 2x20' Freezer Container"],
 ['H30', "40' Flatbed Truck, (FBT) with 2x20' HMMV Container"],
 ['H27', "40' Flatbed Truck, (FBT) with 2x20' Refrigerated Container"],
 ['4FR', "40' Flatbed Truck, (FBT) With Ramps"],
 ['4FS', "40' Flatbed Truck, (FBT) With Sides"],
 ['17', "40' FLATBED W/ CONTAINER"],
 ['XC', "40' FLATBED W/ HMMWV CONTAINER"],
 ['WT', "40' FLATBED W/ RAMPS"],
 ['16', "40' FLATBED W/ SIDES"],
 ['4LB', "40' Low Boy (LB)"],
 ['18', "40' LOWBOY"],
 ['H39', "40' Lowboy Trailer (LB) with 20' Container"],
 ['H40', "40' Lowboy Trailer (LB) with 2x20' Container"],
 ['H42', "40' Lowboy Trailer (LB) with 2x20' HMMV Container"],
 ['H41', "40' Lowboy Trailer (LB) with 40' Container"],
 ['XS', "40' LOWBOY W/ HMMWV CONTAINER"],
 ['SLB', "40' Super Low Boy (SLB-HET Equivalent)"],
 ['XT', "40' SUPER LOWBOY"],
 ['XZ', "40' SUPER LOWBOY W/ HMMWV CONTAINER"],
 ['4NR', "40' Container, Non-Refrigerated"],
 ['4FT', "40' Flatbed Truck, (FBT)"],
 ['H24', "40' Flatbed Truck, (FBT) with 20' Container"],
 ['H28', "40' Flatbed Truck, (FBT) with 20' Freezer Container"],
 ['H34',
  "40' Flatbed Truck, (FBT) with 20' Freezer Container and 20' Refrigerated Container"],
 ['H35', "40' Flatbed Truck, (FBT) with 20' Freezer Container and 20'Regular"],
 ['H32', "40' Flatbed Truck, (FBT) with 20' HMMV Container and 20' Freezer"],
 ['H33',
  "40' Flatbed Truck, (FBT) with 20' HMMV Container and 20' Refrigerated"],
 ['H31',
  "40' Flatbed Truck, (FBT) with 20' HMMV Container and 20' Regular Container"],
 ['H26', "40' Flatbed Truck, (FBT) with 20' Refrigerated Container"],
 ['H36',
  "40' Flatbed Truck, (FBT) with 20' Refrigerated Container and 20'Regular Container"],
 ['H25', "40' Flatbed Truck, (FBT) with 2x20' Containers"],
 ['H29', "40' Flatbed Truck, (FBT) with 2x20' Freezer Container"],
 ['H30', "40' Flatbed Truck, (FBT) with 2x20' HMMV Container"],
 ['H27', "40' Flatbed Truck, (FBT) with 2x20' Refrigerated Container"],
 ['4FR', "40' Flatbed Truck, (FBT) With Ramps"],
 ['4FS', "40' Flatbed Truck, (FBT) With Sides"],
 ['4LB', "40' Low Boy (LB)"],
 ['H39', "40' Lowboy Trailer (LB) with 20' Container"],
 ['H40', "40' Lowboy Trailer (LB) with 2x20' Container"],
 ['H42', "40' Lowboy Trailer (LB) with 2x20' HMMV Container"],
 ['H41', "40' Lowboy Trailer (LB) with 40' Container"],
 ['SLB', "40' Super Low Boy (SLB-HET Equivalent)"],
 ['MA', '5-Ton Truck - Mercedes'],
 ['CH', 'ARMY Air -- CH-47'],
 ['FW', 'Army Air -- Fixed Wing'],
 ['UH', 'Army Air -- UH-1'],
 ['23', 'BAGGAGE TRUCK'],
 ['BG', 'Barge'],
 ['21', 'BOBTAIL'],
 ['BX', 'Boxcar'],
 ['NH', 'Brown & Root 25-Ton Lowboy Trailer'],
 ['NG', 'Brown & Root 30-Ton Lowboy Trailer'],
 ['NF', 'Brown & Root 50-Ton Lowboy Trailer'],
 ['NA', 'Brown & Root Cargo Truck -- Volvo'],
 ['NE', 'Brown & Root Drop Side Trailer'],
 ['NC', 'Brown & Root Flatbed Trailer'],
 ['ND', 'Brown & Root Soft Side Trailer'],
 ['NB', 'Brown & Root Tractor -- Volvo'],
 ['BW', 'Bundeswehr Prime Movers - 6966 TTT'],
 ['BU', 'Bus'],
 ['I8', 'Commercial Bobtail (FB)'],
 ['I6', 'Commercial Bobtail (HET)'],
 ['CB', 'Commercial Bus'],
 ['CT', 'Commercial Highway'],
 ['GD', 'Commercial Less than Car Load (LCL)'],
 ['CL', 'Commercial Lowboy'],
 ['I4', 'COMMERCIAL TRACTOR W/ HET TRAILER'],
 ['CJ', 'Commercial Trailer (HET)'],
 ['GC', 'Commercial Truck -- General'],
 ['VT', 'Commercial Van Truck'],
 ['I3', 'COMMERIAL TRACTOR W/ FB TRAILER'],
 ['CR', 'CRANE'],
 ['C3', 'CRANE 101-180 TONS'],
 ['C1', 'CRANE 1-45 TONS'],
 ['C2', 'CRANE 46-100 TONS'],
 ['DF', 'Deepwell Flat Car -- Rail'],
 ['DA', 'Driver Only - Driveaway Missions'],
 ['RE', 'DV 43 RTCH'],
 ['FBB', 'Flatbed Bobtail'],
 ['FC', 'Flatcar'],
 ['FH', 'Flatcar -- Heavy-Duty'],
 ['FS', 'Flatcar -- Special Purpose'],
 ['F2', 'FORKLIFT 10K'],
 ['F3', 'FORKLIFT 22K'],
 ['F1', 'FORKLIFT 6K'],
 ['19', 'FREEZER'],
 ['FZT', 'Freezer Truck'],
 ['H22', 'Fuel Tanker Bobtail'],
 ['GK', 'Guard Car -- Rail'],
 ['HZ', 'Hazardous Cargo'],
 ['W1', 'HET 70T COMMERCIAL'],
 ['W2', 'HET 80T COMMERCIAL'],
 ['22', 'HMMWV CONTAINER'],
 ['LBB', 'Low Boy Bobtail'],
 ['H43', 'Lowboy Bobtail'],
 ['L1', 'LWK 10-Ton Truck (German)'],
 ['L2', 'LWK 15-Ton Truck (German)'],
 ['LK', 'LWK 5-Ton/7-Ton Truck (German)'],
 ['WB', 'M1000 HET Trailers'],
 ['I1', 'M1070 HET AND M1000 TRAILER'],
 ['WA', 'M1070 HET Tractor'],
 ['PL', 'M1074 PLS with Crane'],
 ['PS', 'M1075 without Crain'],
 ['PT', 'M1076 Trailer PLS'],
 ['MD', 'M1088 Tractor'],
 ['ME', 'M1088 Tractor with M871 Trailer'],
 ['FB', 'M127 -- 27 Foot Flatbed Semi Trailer'],
 ['SP', 'M127 Stake and Platform Semi Trailer'],
 ['LB', 'M172A1/M870 LOWBED TRL'],
 ['MM', 'M208 Mortuary Van -- Mercedes Benz'],
 ['LV', 'M210 Light Van'],
 ['LC', 'M35/M36 2 1/2-Ton Cargo Truck'],
 ['HC', 'M54 5-Ton Cargo'],
 ['HT', 'M747/M1000 HET Trailer'],
 ['MB', 'M871 -- 30 Foot without Sideboards'],
 ['ML', 'M871 30 Foot Dropside Semi Trailer'],
 ['MS', 'M871 30 Foot with Sideboards'],
 ['CD', 'M871 Set Up for 20 Foot Container'],
 ['CX', 'M872 Set Up for 20 Foot Containers'],
 ['CZ', 'M872 Set Up for 40 Foot Container'],
 ['RV', 'M872A3 W/40-Foot Reefer'],
 ['TH', 'M911/M1070 HET Tractors'],
 ['I0', 'M915 TRACTOR AND M872 TRAILER'],
 ['T9', 'M915/931/923 Tractor'],
 ['LH', 'M915A3 LHS (LOAD HANDLING SYSTEM)'],
 ['MT', 'M920 Medium Equipment Transporter'],
 ['UT', 'M923 5-TON Fuel Truck'],
 ['UC', 'M927 EXT'],
 ['UB', 'M931 5-Ton Tractor'],
 ['TK', 'M969 5000-Gallon Tanker'],
 ['UX', 'M984 HEMMIT Wrecker'],
 ['US', 'M998 HUMMV'],
 ['TC', 'Military Passenger Car'],
 ['SC', 'Military Sedan Car'],
 ['MC', 'MILVAN Chassis'],
 ['MV', 'MILVAN on Chassis M129'],
 ['MX', 'MILVAN on M871'],
 ['PV', 'Passenger Carrying Vehicle -- (Other)'],
 ['PC', 'Passenger Coach Car -- Rail'],
 ['PD', 'Personnel Detail'],
 ['ET', 'Rail -- Existing Traffic'],
 ['TN', 'Rail -- Special Train'],
 ['RT', 'Rail Tank Car -- Rail'],
 ['20', 'REEFER'],
 ['RFT', 'Refrigerated Truck'],
 ['KA', 'RT240 KALMAR'],
 ['RS', 'SC209 -- Refrigerated Van Semi Trailer'],
 ['SV', 'Security Van Semi Trailer'],
 ['TX', 'TANK CAR']]

 export const findAssetCode = (asset) => {
    const found = assetsArray.find(row => row[1] === asset);
    return found ? `${found[0]} - ${found[1]}` : '';      
 };
  


export default findAssetCode